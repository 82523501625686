import React from "react"

import Form3Img from "../../images/FORM3.webp"
import Form3BImg from "../../images/FORM3B.webp"
import Form3LImg from "../../images/FORM3L.webp"
import Fuse1Img from "../../images/FUSE1.webp"
import WashLImage from "../../images/wash_l.png"
import CureLImage from "../../images/cure-l.jpg"
import SiftImage from "../../images/sift.webp"
import PortfolioCard from "../../components/PortfolioCard"

const Formlabs = () => (
  <div id="portfolio-formlabs">
    <div id="portfolio-description">
      <p>
        At Formlabs I am the technical lead for the factory software group. On
        this team we develop software that helps assemble, validate and
        calibrate our products using a custom testing & calibration framework
        built using standard web technologies that factory operations use to
        interact with hardware.
      </p>
      <p>
        To see some details on the products I worked on and this framewok helped
        build click on the tiles below:
      </p>
    </div>
    <div id="portfolio-cards-formlabs" className="portfolio__group">
      <PortfolioCard
        title="Sift"
        description="Post processing station for SLS products. Summer 2020."
        img={SiftImage}
        link="https://formlabs.com/post-processing/fuse-sift/"
      />
      <PortfolioCard
        title="Fuse1/1+"
        description="Fuse1 large format SLS 3D printer. Summer 2020."
        img={Fuse1Img}
        link="https://formlabs.com/3d-printers/fuse-1/"
      />
      <PortfolioCard
        title="Form3/3+"
        description="Form3 SLA 3D printer. Spring 2019."
        img={Form3Img}
        link="https://formlabs.com/3d-printers/form-3/"
      />
      <PortfolioCard
        title="Form3B"
        description="Form3B SLA 3D printer for biocompatible materials. Winter 2019."
        img={Form3BImg}
        link="https://dental.formlabs.com/form-3b/"
      />
      <PortfolioCard
        title="Form3L"
        description="Form3L large format SLA 3D printer. Spring 2019."
        img={Form3LImg}
        link="https://formlabs.com/3d-printers/form-3l/"
      />
      <PortfolioCard
        title="Wash L"
        description="IPA washing station for large format SLA products. Fall 2021."
        img={WashLImage}
        link="https://formlabs.com/post-processing/wash-l-cure-l/"
      />
      <PortfolioCard
        title="Cure L"
        description="Post-curing device for large format SLA products. Fall 2021."
        img={CureLImage}
        link="https://formlabs.com/post-processing/wash-l-cure-l/"
      />
    </div>
  </div>
)

export default Formlabs
